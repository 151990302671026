import React from "react";
import { Helmet } from "react-helmet-async";

const CommonMeta = () => {
  const loggedin_Name = localStorage.getItem("login_data");
  const parsedData = JSON.parse(loggedin_Name);
  const user = {
    name: parsedData?.data?.name,
    avatar: "/static/images/avatars/1.jpg",
  };
  return (
    <>
      <Helmet>
        <title>{`${
          user?.name
            ? `${user?.name} | National Web Quality Index`
            : `National Web Quality Index`
        }`}</title>
      </Helmet>
    </>
  );
};

export default React.memo(CommonMeta);
