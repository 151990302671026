export const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "background.paper",
    borderRadius: "10px !important",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgb(10 37 64 / 21%) 0px -2px 6px 0px inset !important",
    p: 4,
  };

 export const formattedLastLogin = (time) => {
    const dateString = time;
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

 export const lastUpdated = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp);
      const options = { day: "numeric", month: "long", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    } else {
      return "Invalid date";
    }
  };

  export const checkLoginTime = (navigate) => {
    const loginTime = localStorage.getItem('backend_loginTime');
    if (loginTime) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(loginTime);
  
      // 120 minutes in milliseconds
      const fiveMinutes = 120 * 60 * 1000;
  
      if (timeDifference > fiveMinutes) {
        // Remove the timestamp from local storage
        localStorage.removeItem('backend_loginTime');
        localStorage.removeItem('login_data');
        localStorage.removeItem('token');
        navigate(`/`)
      }
    }
  };