import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { SidebarProvider } from './contexts/SidebarContext'
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import "./css/Login.css";
// import "./css/Dashboard.css";


ReactDOM.render(
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <ToastContainer />
          <App />
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>,
  document.getElementById("root")
);
