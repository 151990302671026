import React from "react";
import { PropagateLoader } from "react-spinners";

const Loading_Screen = ({ loading }) => {
  return (
    <div
      className="d-flex"
      style={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
      }}
    >
      <PropagateLoader color="#3353e0" />
    </div>
  );
};

export default Loading_Screen;
