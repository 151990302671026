import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import router from "src/routes/router";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import React, { useEffect } from "react";
import { checkLoginTime } from "./common/commonState";
import { loadScript, setDefaultHeader } from "./helpers/utils";
import axios from "axios";
import { debounce } from "lodash";
import CommonMeta from "./common/CommonMeta";
import { checkAuthenticate, frontendApiBase } from "./helpers/Config";

function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");

  const checkAuthentication = () => {
    const apiUrl = `${frontendApiBase}${checkAuthenticate}`;
    if (token?.length > 0) {
      axios
        .post(
          apiUrl,
          {},
          {
            headers: {
              accept: "*/*",
              "Auth-Token": `${token}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.message === "User not authorized") {
            localStorage.removeItem("backend_loginTime");
            localStorage.removeItem("login_data");
            localStorage.removeItem("token");
            navigate(`/`);
          }
          console.log("User authentication response.", response?.data);
          return response?.data;
        })
        .catch((error) => {
          console.log("Error occured in checking authenticity.", error);
        });
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
    } else {
      navigate("/");
    }
  }, [localStorage]);

  useEffect(() => {
    checkLoginTime(navigate);
    setDefaultHeader();
  }, []);

  React.useEffect(() => {
    if (location?.pathname !== "/" && token) {
      checkAuthentication();
    }
  }, [location?.pathname]);

  React.useEffect(() => {
    const dashboardCss = document.createElement("link");
    dashboardCss.rel = "stylesheet";
    dashboardCss.href = "/css/dashboard.css";

    const loginCss = document.createElement("link");
    loginCss.rel = "stylesheet";
    loginCss.href = "/css/login.css";

    // Append CSS And Js files to the head
    document.head.appendChild(dashboardCss);
    document.head.appendChild(loginCss);
    return () => {
      document.head.removeChild(dashboardCss);
      document.head.removeChild(loginCss);
    };
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <CommonMeta />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
