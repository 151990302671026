export const baseUrl = process.env.REACT_APP_API_BASE;
export const nicBaseUrl = process.env.REACT_APP_MINISTRY_API_BASE;
export const frontendApiBase = process.env.REACT_APP_FRONTEND_API_BASE;

export const loginRoute = "/login";
export const checkAuthenticate = "/api/checkAuthenticate";
export const updateManualReport = "/sites/savemanual";
export const getUser = "/user/get/";
export const siteQueue = `/sites/queue?id=`;
export const getSite = `/sites/byid/`;
export const siteByUser = `/sites/byuser?user=`;
export const freeText = "/sites/freetext";
export const getFreeText = "/sites/getfreetext";
export const settings = `/settings`;
export const frameWorkQuestion = `/questions`;
export const getManualQuestionsApi = `/sites/getmanual`;
export const getDashboardData = `/dashboard`;
export const adminUser = `/users?type=1`;
export const createUser = `/register`;
export const resetPass = `/resetpass`;
export const userLog = `/logs/`;
export const getQueuedSites = `/sites/queue`;
export const downloadReport = `/report/`;
export const getAllUsers = `/users`;
export const bulkUploadSite = `/sites/uploadsites`;
export const getMinistry = `/ministries`;
export const getRegisteredEmails = `/emails`;
export const getDepartments = `/departments/`;
export const siteStatusChange = `/sites/stchange/`;
export const manualQuestionApi = `/sites/manual`;
export const updateUser = `/user/update/`;
export const deleteuser = `/user/delete/`;
export const softDeleteUser = `/users/get_soft_deleted/`;
export const createSite = `/sites/create`;
export const updateSite = `/sites/update/`;
export const getAllSite = `/sites/get?status=1`;
export const deleteSite = `/sites/delete/`;
export const getRequestedSite = `/sites/get?status=1`;
export const createManualUser = `/manual-user-register`;
export const deleteFreeText = `/freeText`;
export const viewFreeText = `/freeText`;
export const updateFreeText = `/freeText`;
export const getNICMinistry = `/ug_ministries_departments`;
export const getNICDepartments = `/ug_departments/`;
export const getChildOrganisation = `/list_child_organizations/`;
export const organisationTypes = `/vocabcategory/categories/org_types`;
export const updateSettingsUrl = `/settings-update`;
export const siteMapping = "/mapping-site-manual-user/";
export const revokeQueue = `/queue/delete/`;
export const approveSiteApi = "/site/approve";
export const activityLogApi = `/logs`;
