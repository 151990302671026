import axios from "axios";
import { token } from "src/common/apiCommonData";
import { encode as base64_encode } from "js-base64";

export const userTypeData = [
  {
    id: 1,
    title: "Total users",
    userCount: "",
    icon: "fa-solid fa-users",
  },
  {
    id: 2,
    title: "Registered users",
    userCount: "",
    icon: "fa-solid fa-thumbs-up",
  },
  {
    id: 3,
    title: "Awaited users",
    userCount: "",
    icon: "fa-regular fa-thumbs-down",
  },
];

export const setDefaultHeader = async () => {
  const token = await localStorage.getItem(`token`);
  // axios.defaults.headers.common["Auth-Token"] = token;
};

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const formattedDate = (date, time) => {
  const updated_date = new Date(+date);
  const date_options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const date_time_options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedDateString = time
    ? updated_date
        .toLocaleString("en-US", date_time_options)
        .replace(",", " at")
    : updated_date?.toLocaleDateString("en-US", date_options);
  return formattedDateString;
};

export const convertTimeToShortFormat = (timeString) => {
  const timeRegex = /(\d+)\s*minutes?\s*(\d+)\s*seconds?/i;
  const matches = timeString.match(timeRegex);
  if (!matches) {
    return timeString;
  }
  const minutes = parseInt(matches[1], 10);
  const seconds = parseInt(matches[2], 10);
  let result = "";

  if (minutes > 0) {
    result += `${minutes}min`;
  }
  if (seconds > 0 || minutes === 0) {
    result += ` ${seconds}sec`;
  }

  return result;
};
export function loginDateTime(timestampString) {
  // Create a new Date object from the timestamp string
  const date = new Date(timestampString);

  // Get individual components of the date
  const dayOfWeek = date.toLocaleString("en", { weekday: "short" });
  const dayOfMonth = date.getUTCDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en", { month: "short" });
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  // Construct the formatted string
  const formattedDate = `${dayOfWeek}, ${dayOfMonth} ${month} ${year}, ${hours}:${minutes}`;

  return formattedDate;
}

export function getDate(time) {
  const date = new Date(time);
  const dayOfWeek = date.toLocaleString("en", { weekday: "short" });
  const month = date.toLocaleString("en", { month: "short" });
  const dayOfMonth = date.getDate().toString().padStart(2, "0"); // Ensure day is zero-padded
  const year = date.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth} ${year}`;

  // Render the formatted date
  return <div>{formattedDate}</div>;
}

export const loadScript = () => {
  const dashboardCss = document.createElement("link");
  dashboardCss.rel = "stylesheet";
  dashboardCss.href = "/css/dashboard.css";

  const loginCss = document.createElement("link");
  loginCss.rel = "stylesheet";
  loginCss.href = "/css/login.css";

  // Append CSS files to the head
  document.head.appendChild(dashboardCss);
  document.head.appendChild(loginCss);
};

export const removeScript = () => {
  document.head.removeChild(dashboardCss);
  document.head.removeChild(loginCss);
};

export const getRandom8Digits = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < 8; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

export const splitStringWithRandomDigits = (str) => {
  let parts = splitStringIntoThreeParts(str); // Split the string into three parts

  return `${getRandom8Digits()}$${base64_encode(
    base64_encode(parts[0])
  )}$${getRandom8Digits()}$${base64_encode(
    base64_encode(parts[1])
  )}$${getRandom8Digits()}$${base64_encode(base64_encode(parts[2]))}`;
};

export const splitStringIntoThreeParts = (str) => {
  if (str.length <= 2) {
    return [str, "", ""];
  } else if (str.length <= 4) {
    return [str.slice(0, 2), str.slice(2), ""];
  } else {
    return [str.slice(0, 2), str.slice(2, 4), str.slice(4)];
  }
};
